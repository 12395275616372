export const validChatAttachmentMimeTypes = [
  "application/pdf",
  "application/msword", // DOC
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
  "image/png", // PNG
  "image/jpeg", // JPG, JPEG
  "application/vnd.ms-excel", // XLS
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
];

export const MIME_TYPE_PDF = "application/pdf";
export const MIME_TYPE_WORD = "application/msword";
export const MIME_TYPE_DOCX =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const MIME_TYPE_EXCEL = "application/vnd.ms-excel";
export const MIME_TYPE_XLSX =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const chatImageAttachmentHeight = 150;
